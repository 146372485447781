import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "./DicomViewModal.css";

interface IImageViewModal {
  show: boolean
  setShow: any
  imageData: any
}

export default function ImageViewModal(props: IImageViewModal) {

  return (
    <>
      <Modal show={props.show} fullscreen={true} onHide={() => props.setShow(false)} centered className="dicom-modal-fullscreen">
        <Modal.Title className="text-white m-3"><FontAwesomeIcon icon={faClose} onClick={() => props.setShow(false)} className="me-3 dcm-link-cursor" /> Image Control View</Modal.Title>
        <Modal.Body>
          <TransformWrapper
            initialScale={1}
            initialPositionX={0}
            initialPositionY={0}
          >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <div className="d-flex justify-content-center align-items-center">
                <div >
                  <div className="tools mb-3 d-flex justify-content-center align-items-center">
                    <Button onClick={() => zoomIn()} variant="primary" className="me-2">Zoom +</Button>
                    <Button onClick={() => zoomOut()} variant="primary" className="me-2">Zoom -</Button>
                    <Button onClick={() => resetTransform()} variant="warning" className="me-2">Reset</Button>
                  </div>
                  <TransformComponent>
                    <div style={{ height: "600px", width: "700px" }} className="d-flex justify-content-center align-items-center mt-3">
                      <img src={props.imageData} height="100%" width="100%" style={{ objectFit: "contain" }} />
                    </div>
                  </TransformComponent>
                </div>
              </div>
            )}
          </TransformWrapper>
        </Modal.Body>
      </Modal>
    </>
  );
}