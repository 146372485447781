import React, { useState, useEffect, useRef } from 'react';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import cornerstoneMath from 'cornerstone-math';
import Hammer from 'hammerjs';
import dicomParser from 'dicom-parser';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import { KeyboardKey, useKeyPress } from './useKeyDown.hook';

cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

interface IProps {
  onClose: () => void;
  links: any[];
  show?: boolean;
  hideTools?: boolean;
  activeToolParent?: string;
}

const CornerStoneDicomViewer = ({ onClose, links, show = true, hideTools = false, activeToolParent }: IProps) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState([]);
  const [windowWidth, setWindowWidth] = useState(128);
  const [windowCenter, setWindowCenter] = useState(128);
  const [zoom, setZoom] = useState(0.75);
  const [activeTool, setActiveTool] = useState<string>("");
  const elementRef = useRef(null);
  const [loadingImages, setLoadingImages] = useState<boolean>(false);

  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;

    cornerstone.enable(element);

    cornerstoneTools.init({
      mouseEnabled: true,
      touchEnabled: true,
      globalToolSyncEnabled: false,
      showSVGCursors: true,
    });

    // cornerstoneTools.toolStyle.setToolWidth(5);
    cornerstoneTools.toolColors.setToolColor("red");

    cornerstoneTools.addTool(cornerstoneTools.PanTool);
    cornerstoneTools.addTool(cornerstoneTools.ZoomTool);
    cornerstoneTools.addTool(cornerstoneTools.WwwcTool);
    cornerstoneTools.addTool(cornerstoneTools.StackScrollMouseWheelTool);
    cornerstoneTools.addTool(cornerstoneTools.ZoomMouseWheelTool);
    cornerstoneTools.addTool(cornerstoneTools.MagnifyTool);
    cornerstoneTools.addTool(cornerstoneTools.RotateTool);
    cornerstoneTools.addTool(cornerstoneTools.LengthTool);
    cornerstoneTools.addTool(cornerstoneTools.AngleTool);
    // cornerstoneTools.addTool(cornerstoneTools.BrushTool);
    cornerstoneTools.addTool(cornerstoneTools.ProbeTool);
    cornerstoneTools.addTool(cornerstoneTools.RectangleRoiTool);
    cornerstoneTools.addTool(cornerstoneTools.EllipticalRoiTool);


    setupInitialTools();

    element.addEventListener('cornerstoneimagerendered', onImageRendered);
    return () => {
      cornerstone.disable(element);
      element.removeEventListener('cornerstoneimagerendered', onImageRendered);
    };
  }, [imagesLoaded]);

  const stack = {
    currentImageIdIndex: currentImageIndex,
    imageIds: imagesLoaded
  }

  // console.log(cornerstoneTools, "cornerstoneTools");
  useEffect(() => {
    cornerstoneTools.addStackStateManager(elementRef.current, ['stack']);
    cornerstoneTools.addToolState(elementRef.current, 'stack', stack);
  }, [imagesLoaded]);

  const setupInitialTools = () => {
    // cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 3 });
    cornerstoneTools.setToolActive('Zoom', { mouseButtonMask: 2 });
    cornerstoneTools.setToolActive('StackScrollMouseWheel', { mouseButtonMask: 0 });
  };

  function handleToolChange(tool: string) {
    console.log('tool changed', tool);
    // console.log(cornerstoneTools.isToolActiveForElement(elementRef.current, tool))

    Object.keys(tools).map((key) => {
      if (key !== tool && cornerstoneTools.isToolActiveForElement(elementRef.current, key)) {
        cornerstoneTools.setToolPassive(key);
      }
    })

    // if (cornerstoneTools.isToolActiveForElement(elementRef.current, tool)) {
    //   cornerstoneTools.setToolPassive(tool);
    //   setActiveTool("");
    //   console.log('tool passive', tool);
    // } else {
    // }
    cornerstoneTools.setToolActive(tool, { mouseButtonMask: 1 });
    setActiveTool(tool);
  }

  function handlePlayClip() {
    // console.log(cornerstoneTools.getToolState(elementRef.current, 'playClip'), 'playClip');
    // cornerstoneTools.addToolState(elementRef.current, 'playClip', {loop:false});
    cornerstoneTools.playClip(elementRef.current, 30);
  }

  function handleClear() {
    cornerstoneTools.clearToolState(elementRef.current, activeTool)
    cornerstone.updateImage(elementRef.current);
  }

  const handleScrollChange = (newIndex) => {
    setCurrentImageIndex(newIndex);
    loadAndDisplayImage(newIndex);
  };


  const handleFileChange = (event: any) => {
    const files = event.target.files;
    const imageIds: any = Array.from(files).map(file => cornerstoneWADOImageLoader.wadouri.fileManager.add(file));
    setImagesLoaded(imageIds);
    setCurrentImageIndex(0);
    loadAndDisplayImage(0);
  };

  const loadAndDisplayImage = async (index) => {
    const imageId = imagesLoaded[index];
    if (imageId) {
      await cornerstone.loadAndCacheImage(imageId)
        .then(image => {
          const viewport = cornerstone.getDefaultViewportForImage(elementRef.current, image);
          viewport.voi.windowWidth = windowWidth;
          viewport.voi.windowCenter = windowCenter;
          viewport.scale = zoom;
          cornerstone.displayImage(elementRef.current, image, viewport);
        })
        .catch(err => console.log(err, "Error loading image"))
        .finally(() => {
          setCurrentImageIndex(index);
        })
    }
  };

  const updateViewport = () => {
    try {
      const element = elementRef.current;
      const viewport = cornerstone.getViewport(element);
      viewport.voi.windowWidth = windowWidth;
      viewport.voi.windowCenter = windowCenter;
      viewport.scale = zoom;
      cornerstone.setViewport(element, viewport);
      cornerstone.updateImage(element);
    } catch (err) {
      console.error('Unhandled exception in updateViewport()', err);
    }
  };


  function loadFileFromLink() {
    try {
      const imageIds = [];

      const promises = links.map(link =>
        fetch(link)
          .then(response => response.arrayBuffer())
          .then(buffer => {
            const file = new Blob([buffer], { type: 'application/dicom' });
            const imageId = cornerstoneWADOImageLoader.wadouri.fileManager.add(file);
            imageIds.push(imageId);
          })
          .catch(err => {
            console.error(err, "error while loading image from link");
          })
      );

      setLoadingImages(true);
      Promise.all(promises)
        .then(() => {
          setImagesLoaded(imageIds);
          setCurrentImageIndex(0);
          loadAndDisplayImage(0);
        })
        .catch(err => {
          console.error(err, "error while loading images from links");
        })
        .finally(() => {
          setLoadingImages(false);
        })
    } catch (err) {
      console.error(err, "error while loading images from links");
    }
  }

  const onImageRendered = (event) => {
    // console.log('onImageRendered', event.detail);
    // console.log('currntimageindex' , currentImageIndex);
    // console.log('imagesloaded' , imagesLoaded);
    const eventData = event.detail;
    setWindowWidth(Math.round(eventData?.viewport?.voi?.windowWidth));
    setWindowCenter(Math.round(eventData?.viewport?.voi?.windowCenter));
    setCurrentImageIndex(imagesLoaded.indexOf(eventData.image.imageId));
  };

  useEffect(() => {
    updateViewport();
  }, [windowWidth, windowCenter, zoom]);

  useEffect(() => {
    if (imagesLoaded.length > 0) {
      setCurrentImageIndex(0);
      loadAndDisplayImage(0);
    }
  }, [imagesLoaded]);

  useEffect(() => {
    if (show && links.length > 0) {
      loadFileFromLink();
    }
  }, [show, links]);

  useEffect(() => {
    if (activeToolParent) {
      handleToolChange(activeToolParent);
    }
  }, [activeToolParent])

  const handleKeyPress = (pressedKey?: KeyboardKey) => {
    if (pressedKey) {
      handleToolChange(pressedKey);
    }
  };
  useKeyPress(handleKeyPress, [KeyboardKey.P, KeyboardKey.L, KeyboardKey.W, KeyboardKey.M, KeyboardKey.A, KeyboardKey.R, KeyboardKey.E, KeyboardKey.T, KeyboardKey.D]);

  return (
    <div className="mt-2">
      {
        !loadingImages ?
          <>
            {/* <Button size='sm' variant='warning' className='mb-2' onClick={onClose}>{'< Back'}</Button> */}
            {/* <div className='d-flex align-items-center justify-content-center'>
              <Row className='w-100'>

                {
                  !hideTools &&
                  Object.keys(tools).map((key) => {
                    return (
                      <Col md={2}>
                        <Button key={key} size='sm' className='mb-2' variant={activeTool === key ? 'danger' : 'primary'} onClick={() => handleToolChange(key)}>{key}</Button>
                      </Col>
                    )
                  })
                }
                {
                  !hideTools &&
                  <Col md={2}>
                    <Button size='sm' variant='primary' className='mb-2 text-nowrap' onClick={handleClear}>Clear Annotations</Button>
                  </Col>
                }
                <Col md={2}>
                  <Button size="sm" variant='primary' className='mb-2' onClick={() => handlePlayClip()}>Play Clip</Button>
                </Col>
                <Col md={2}>
                  <Button size='sm' variant='primary' onClick={() => cornerstoneTools.stopClip(elementRef.current)}>Stop Clip</Button>
                </Col>
              </Row>
            </div> */}
            <Row className='mt-2'>
              {
                !hideTools &&
                <Col md={3}>
                  <Select
                    options={toolsOptions}
                    onChange={(option): any => handleToolChange(option.value)}
                    value={toolsOptions.find((option) => option.value === activeTool) || null}
                    placeholder="Select Tool"
                  />
                </Col>
              }
              <Col md={6} className='d-flex align-items-center  gap-3'>
                {
                  !hideTools &&
                  <Button size='sm' variant='primary' className=' text-nowrap' onClick={handleClear}>Clear Annotations</Button>
                }
                <Button size="sm" variant='primary' className='' onClick={() => handlePlayClip()}>Play Clip</Button>
                <Button size='sm' variant='primary' onClick={() => cornerstoneTools.stopClip(elementRef.current)}>Stop Clip</Button>
              </Col>
            </Row>
            {/* <input type="file" onChange={handleFileChange} multiple accept=".dcm" /> */}
            <div className="" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
              <div
                ref={elementRef}
                style={{ minWidth: '500px', minHeight: '500px', position: 'relative', color: 'white', border: '1px solid black', userSelect: 'none' }}
                onContextMenu={(e) => e.preventDefault()}
                className=''
              ></div>
            </div>
            <div className="d-flex align-items-center justify-content-center mt-2 gap-2">
              <Button variant='primary' onClick={() => loadAndDisplayImage((currentImageIndex - 1 > 1 ? currentImageIndex - 1 : imagesLoaded.length - 1) % imagesLoaded.length)}>Previous Image</Button>
              <Button onClick={() => loadAndDisplayImage((currentImageIndex + 1) % imagesLoaded.length)}>Next Image</Button>
            </div>
            <div className="d-flex align-items-center justify-content-center mt-2 text-white">
              {/* Window Width: {windowWidth} <br />Window Center: {windowCenter} 
              <br /> */}
              <span className='fs-6 text-light'>
                Current Image: {currentImageIndex + 1} / {imagesLoaded.length}
              </span>
            </div>
          </>
          :
          <>
            <div className="d-flex align-items-center justify-content-center h-100">
              <Spinner animation={'border'} variant='primary' />
              <span className='fs-1 ms-2 text-white'>Loading Images</span>
            </div>
          </>
      }
    </div>
  );
};

export default CornerStoneDicomViewer;

export const tools = {
  "Wwwc": "Wwwc",
  "Pan": "Pan",
  'Magnify': 'Magnify',
  "Length": "Length",
  "Angle": "Angle",
  // "Brush": "Brush",
  "Probe": "Probe",
  "RectangleRoi": "RectangleRoi",
  "EllipticalRoi": "EllipticalRoi",
  "Rotate": "Rotate",
}

export const toolsOptions = [
  {
    value: 'Pan',
    label: 'Pan (p)'
  },
  {
    value: 'Wwwc',
    label: 'Wwwc (w)'
  },
  {
    value: 'Magnify',
    label: 'Magnify (m)'
  },
  {
    value: 'Length',
    label: 'Length (l)'
  },
  {
    value: 'Angle',
    label: 'Angle (a)'
  },
  {
    value: 'Probe',
    label: 'Probe (d)'
  },
  {
    value: 'RectangleRoi',
    label: 'RectangleRoi (r)'
  },
  {
    value: 'EllipticalRoi',
    label: 'EllipticalRoi (e)'
  },
  {
    value: 'Rotate',
    label: 'Rotate (t)'
  }
]