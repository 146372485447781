import makeRequestPublic from "../api/makeRequest.public";
import { RequstMethods } from "../api/requestMethode";
import url from "../api/urls";

export default class PublicService {
    static async getAllLiveExams(){
        return await makeRequestPublic(url.public.getAllLiveExams, RequstMethods.GET);
    }

    static async getQuestionDataByExamId(examId: string){
        return await makeRequestPublic(url.public.getQuestionDataByExamId+"/"+examId, RequstMethods.GET);
    }

}