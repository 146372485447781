import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap';
import DicomViewModal from '../../../../../components/DicomViewModal/DicomViewModal';
import LiveExam from '../../../../../Services/liveexam.services';
import { getCachedData } from '../../../../../cache/Load.cache';
import CornerStoneDicomViewer from '../../../../../components/CornerStoneDicomViewer';
import { SelectedQuestion } from '../../context';
import SplitDicomViewerCornerStone from '../../../../../components/SplitDicomViewerCornerStone';


interface IExamQuestionValue {
    value: any,
    response: any
    statement: any;
}


interface IThumbnails {
    url: string,
    dataId: string
    extension: string
    data: any
}

export default function ExamQuestionValue(props: IExamQuestionValue) {


    const [s3Url, setS3Url] = useState<string>();

    const { selectedQuestionId, setSelectedQuestionId } = useContext<any>(SelectedQuestion);

    // const { add, getByID, getByIndex, getAll, deleteRecord } = useIndexedDB("dicom");


    const [loading, setLoading] = useState<boolean>(false);

    const [fileLinks, setFileLinks] = useState<any[]>([]);

    const [thumbnails, setThumbnails] = useState<IThumbnails[]>([]);
    const [showSplitView, setShowSplitView] = useState<boolean>(false);
    const [showDicomView, setShowDicomView] = useState<boolean>(false);


    // const requestS3Url = async () => {
    //     await LiveExam.getS3url().then((res) => {
    //         if (res.status === 200) {
    //             setS3Url(res.data)
    //         }
    //     })
    // }


    const onMount = async () => {
        const thumbnailData = await LiveExam.getQuestionThumbnails(selectedQuestionId.question).then((res) => {
            if (res.status === 200) {
                return res.data?.data?.thumbNails;
            }
            else {
                return []
            }
        }).catch(e => {
            console.error(e)
            return []
        })

        let finalDataThumb = [];
        for (const data of thumbnailData) {
            const thumbData = await getCachedData(data?.thumbnailData);
            finalDataThumb.push({
                url: data?.thumbnailData,
                dataId: data?.questionDataId,
                extension: data?.thumbnailExtension,
                data: thumbData
            })
        }
        setThumbnails(finalDataThumb)

        setLoading(false)

    }

    const getDicomData = async (dataId: any) => {
        await LiveExam.getQuestionData(dataId).then((res) => {
            if (res.status === 200) {
                const links = res.data.data.uploadedFiles;
                const s3Links = links.map((link: any) => link.fileLink)
                setFileLinks(s3Links)
            }
            else {
                setFileLinks([])
            }
        }).catch(e => {
            console.error(e)
            setFileLinks([])
        })
    }

    useEffect(() => {
        refreshSelectedDicom()
        if (props.value) {
            setLoading(true);
            onMount();
        }
        else {
            setLoading(false);
        }
    }, [props.value, s3Url])


    // useEffect(() => {
    //     requestS3Url();
    // }, [])


    const refreshSelectedDicom = () => {
        setFileLinks([])
    }


    const dicomMultiView = () => {
        return (
            <>
                <div className='m-2'>
                    <div className='dcm-view-dicom-main-area'>
                        <Row>
                            {thumbnails && thumbnails.length > 0 &&
                                thumbnails.map((thumbnail, index: number) => {
                                    return (
                                        <Col md={3} onClick={() => getDicomData(thumbnail.dataId)} className="dcm-link-cursor mt-1 text-center">
                                            <Image src={thumbnail.data} height="200px" width="200px" className='p-3 border border-primary border-1' />
                                            <div className='h6 mt-1'>
                                                Dicom {index + 1}
                                            </div>
                                        </Col>
                                    )
                                })

                            }
                        </Row>
                    </div>

                </div>
            </>
        )
    }

    return (
        <>

            <DicomViewModal
                show={showDicomView}
                setShow={() => setShowDicomView(false)}
                links={fileLinks}
                statement={props.statement?.question}
            />

            <SplitDicomViewerCornerStone
                show={showSplitView}
                setShow={() => setShowSplitView(false)}
                links={fileLinks}
                thumbnails={thumbnails}
                statement={props.statement?.question}
            />

            {
                fileLinks.length > 0 &&
                <div className='m-3 p-2 border rounded'>
                    <div className="d-flex justify-content-end align-items-center">
                        <Button variant="warning" size="sm" onClick={() => setFileLinks([])}>
                            Close
                        </Button>
                    </div>
                    <CornerStoneDicomViewer onClose={() => setFileLinks([])} links={fileLinks} show={fileLinks.length > 0} />
                    <div className="d-flex justify-content-center align-items-center">
                        <Button variant="warning" size="sm" onClick={() => setShowDicomView(true)}>
                            View Full Screen
                        </Button>
                    </div>
                </div>
            }
            {
                loading ? <div className="dicom-half-view">
                    <div className="d-flex justify-content-center align-items-center text-primary">
                        Loading Please Wait...
                    </div>
                </div> :
                    <div>
                        {/* <div className="d-flex justify-content-center">
                            <Button size='sm' onClick={() => setShowSplitView(true)}>Open Split View</Button>
                        </div> */}

                        {dicomMultiView()}

                        {/* <CornerStoneDicomViewer
                            onClose={() => setSelectedDicom(undefined)}
                            links={fileLinks}
                        /> */}
                    </div>
            }

        </>
    )
} 