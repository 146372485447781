import { faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Container, Card, Col, Row, Button, FloatingLabel, Form, DropdownButton, Dropdown, Image } from "react-bootstrap";
import toast from "react-hot-toast";
import { useIndexedDB } from "react-indexed-db";
import { useNavigate } from "react-router-dom";
import Auth from "../../auth/auth";
import home_logo from "../../assets/icons/IAPLogo.png";
// import LoadCache from "../../cache/Load.cache";
import fingerPrint from "../../helpers/fingerprintjs";
import { AuthService } from "../../Services/authServices";
import { deleteAllCaches } from "../../cache/Load.cache";

interface ILogin {
  counter: any
}

export default function Login({ counter }: ILogin) {

  // const { add, getByID, getByIndex, getAll, deleteRecord } = useIndexedDB("dicom");

  const [dicomCount, setdicomCount] = useState<number>(0);
  const [localUrl, setLocalUrl] = useState<any>();

  // useEffect(() => {
  //   getAll().then(dicomData => {
  //     setdicomCount(dicomData.length);
  //   });
  // }, [counter]);

  const navigate = useNavigate();
  const [loginData, setLoginData] = useState<any>({
    email: "",
    password: "",
  });






  async function clear() {
    // const dbs = await window.indexedDB.databases();
    // dbs.forEach((db:any) => { window.indexedDB.deleteDatabase(db.name) });
    localStorage.clear();
    sessionStorage.clear();
    deleteCookies();
    deleteAllCaches()
    if (!window.indexedDB) console.log("index db is not supported")
    else {
      window.indexedDB.databases().then((r: any) => {
        console.log(r.length)
        for (var i = 0; i < r.length; i++) window.indexedDB.deleteDatabase(r[i].name);
      }).then(() => {
        // alert('All data cleared.');
        console.log('All data cleared.');
      });
    }
    // window.indexedDB.clear();
  }
  function deleteCookies() {
    var allCookies = document.cookie.split(';');
    for (var i = 0; i < allCookies.length; i++)
      document.cookie = allCookies[i] + "=;expires="
        + new Date(0).toUTCString();
  }

  const onChangeValue = (e: any) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const loginSubmit = async (e: any) => {
    e.preventDefault();

    await AuthService.login(({
      email: loginData.email,
      password: loginData.password,
      devInfo: await fingerPrint()
    }))
      .then(async (res) => {
        if (res.status === 200) {
          await Auth.authenticate();
          localStorage.setItem("authKey", res.data.data.token)
          toast.success("Login Success")
          navigate('/exam/student-info')
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          console.error(err);
          toast.error("Something went wrong")
        } else if (err.response.status === 500) {
          // alert("Alredy Loged in")
          toast.error(err.response.data)

          console.error(err.response.data);
        }
      })
  };

  return (
    <>
      <div style={{ height: "100vh", backgroundColor: '#eeeeee' }}>
        <Container style={{ maxWidth: "400px" }} >
          <h1 className="text-center font-weight-bold xcn-login-header pt-3">
            <img src={home_logo} height="100px;" />
          </h1>
          <h3 className="text-center font-weight-bold">Welcome to Indian College of Padiatrics Examination </h3>
          <p className="text-center mt-2">Sign In ICP Exam</p>

          <Row className="justify-content-md-center mt-4">
            <Col md="12">
              <Card className="p-4 border-0 shadow">
                <>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="User Name"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Username"
                      name="email"
                      onChange={onChangeValue}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingPassword"
                    label="Password"
                    onChange={onChangeValue}
                  >
                    <Form.Control type="password" placeholder="Password" name="password" />
                  </FloatingLabel>
                </>
                <Button
                  variant="primary"
                  type="submit"
                  className="mt-4"
                  size="lg"
                  onClick={loginSubmit}
                >
                  <span className="xcn-text-14">
                    Login
                  </span>
                </Button>
              </Card>
            </Col>
          </Row>
          {/* <hr /> */}
          {/* <Row className="mt-3">
            <Col>
              <Form.Group>
                <Form.Label>Online Server URL</Form.Label>
                <Form.Control defaultValue={localStorage.getItem("onlineUrl") || ""} readOnly />
              </Form.Group>
            </Col>
          </Row> */}


          {/* <Row className="mt-3">
            <Col>
              <Form.Group>
                <Form.Label>Local Server URL</Form.Label>
                <div className="d-flex align-items-center">
                  <Form.Control type="text" value={localStorage.getItem("localUrl") || localUrl} onChange={(e: any) => setLocalUrl(e.target.value)} />
                  <Button className="ms-3" variant="secondary" onClick={() => { setLocalUrl(""); localStorage.removeItem("localUrl") }}>Clear</Button>
                </div>
              </Form.Group>
              <Button className="mt-3" style={{ float: "right" }} onClick={() => localStorage.setItem("localUrl", localUrl)}>Set URL</Button>
            </Col>
          </Row> */}
          <div style={{ position: 'fixed', bottom: "50px", right: "10px" }} className="me-2">
            <div className="bg-white">
              <span>
                Exam:  {counter.examCounter}
                <br />
                Index: {dicomCount}
                <br />
                Image/Data Counter: {counter.questionCounter}
              </span>
            </div>
            {counter.questionCounter === counter.totalQuestions && counter.examCounter === counter.totalExam ?
              "Ready" : <>Downloading {counter.questionCounter + " of " + counter.totalQuestions} Images in {counter.examCounter + " of " + counter.totalExam} Exam </>
            }
          </div>

          <DropdownButton
            align="end"
            title={<FontAwesomeIcon icon={faGear} />}
            id="dropdown-menu-align-end"
            style={{ position: "absolute", bottom: "10px", right: "10px" }}
          >
            <Dropdown.Item onClick={clear}>Clear Memory!</Dropdown.Item>

          </DropdownButton>
        </Container>
      </div>
    </>
  );
};

